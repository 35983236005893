import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const TOS_ASSET_NAME = 'terms-of-service';
export const PRIVACY_POLICY_ASSET_NAME = 'privacy-policy';

export const GET_REFERRAL_USER_INFO = 'app/AuthenticationPage/GET_REFERRAL_USER_INFO';
export const GET_REFERRAL_USER_INFO_SUCCESS = 'app/AuthenticationPage/GET_REFERRAL_USER_INFO_SUCCESS';
export const GET_REFERRAL_USER_INFO_ERROR = 'app/AuthenticationPage/GET_REFERRAL_USER_INFO_ERROR';

// ================ Reducer ================ //

const initialState = {
  referralUser: null,
  referralUserError: null,
  referralUserLoaded: false,
};

const authenticationPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_REFERRAL_USER_INFO:
      return { ...state, referralUserLoaded: false, referralUserError: null };
    case GET_REFERRAL_USER_INFO_SUCCESS:
      return { ...state, referralUser: payload, referralUserLoaded: true };
    case GET_REFERRAL_USER_INFO_ERROR:
      return { ...state, referralUserError: payload, referralUserLoaded: true };
    default:
      return state;
  }
}

export default authenticationPageReducer;

// ================ Action creators ================ //

export const getReferralUserInfo = userId => ({ type: GET_REFERRAL_USER_INFO, payload: userId });
export const getReferralUserInfoSuccess = user => ({ type: GET_REFERRAL_USER_INFO_SUCCESS, payload: user });
export const getReferralUserInfoError = error => ({ type: GET_REFERRAL_USER_INFO_ERROR, payload: error });

// ================ Thunks ================ //

export const loadReferralUserInfo = userId => (dispatch, getState, sdk) => {
  dispatch(getReferralUserInfo(userId));
  return sdk
    .users.show({ id: userId })
    .then(response => {
      dispatch(getReferralUserInfoSuccess(response.data.data));
    })
    .catch(e => {
      dispatch(getReferralUserInfoError(storableError(e)));
    });
}


export const loadData = (params, search) => dispatch => {

  // Get the referralId from the URL search params
  const referralId = new URLSearchParams(search).get('r');

  const pageAsset = {
    termsOfService: `content/pages/${TOS_ASSET_NAME}.json`,
    privacyPolicy: `content/pages/${PRIVACY_POLICY_ASSET_NAME}.json`,
  };

  return Promise.all([
    dispatch(fetchPageAssets(pageAsset, true)),
    referralId ? dispatch(loadReferralUserInfo(referralId)) : null,
  ])
};
